import React from 'react';
import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/homesandgardens.css';

loadCSS('homesandgardens-site');

export const HomesandgardensSiteWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <>{children}</>;
};
export default HomesandgardensSiteWrapper;
